<template>
    <div class="page-social-game-taste-menu">
        <component-bcg url="cabin/c1.png"></component-bcg>
        <div class="taste-menu-body">
            <div class="title ele-item align-center-position">
                <span class="text-large bold">快速匹配</span>
            </div>
            <div class="ele-item rule scale-button text-center" @click="$refs.rule.open()">
                <span class="text-normal bold">升级规则</span>
            </div>
            <div class="ele-item game">
                <div class="game-body flex">
                    <!-- 'scale-button': !v.disabled -->
                    <div class="game-item scale-button" v-for="v in gameListCul" :key="v.id" 
                    @click="tasteStart(v)"
                    :class="[`${v.type}-${v.boardSize}`, v.type, {'disabled': v.disabled}]">
                        <div class="user-photo-box position-align-center-margin" :class="{'disabled-img': v.disabled}">
                            <user-photo></user-photo>
                        </div>
                        <div class="disabled-label position-align-center-margin" v-if="v.disabled"></div>
                    </div>
                </div>
            </div>
        </div>
        <rule ref="rule"></rule>
    </div>
</template>
<script>
    import rule from './components/rule.vue'
    import apiClass from '@/API/page/game'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                gameList: [],
                userInfo: {}
            };
        },
        methods: {
            async pageInit(){
                this.userInfoChange()
                this._userHand.on('menuTaste', this.userInfoChange)
                return Promise.all([
                    this.patternListInit(),
                    this.userLevelInit()
                ])
            },
            async patternListInit(){
                let res = await this.api.configLoad('game')
                if(res) this.gameList = res
            },
            async userLevelInit(){
                await this.api.userLevel({noLoad: true, noError: true})
            },
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
            tasteStart(val){
                val = val || {}
                if(val.disabled) return this.disabledHandler(val)
                this._common.tasteWindow.open(val)
            },
            async disabledHandler(){
                let message = this._errorCode.game['taste disabled level']
                this._voiceHand.normalPlay('taste_level_disabled')
                await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    defineClass: 'padding-c3',
                    buttons: [
                        {title: '确定'}
                    ]
                })
                this._voiceHand.normalStop('taste_level_disabled')
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            rule,
        },
        computed: {
            highestLevel(){
                let highestLevel = this.userInfo.highestLevel
                let {user_level: list} = this.PARAMS
                let f = false, min = null
                for(let i in list){
                    let {value} = list[i]
                    if(highestLevel >= value) {
                        f = true
                        break
                    }
                    if(min === null || value < min) min = value
                }
                if(!f) highestLevel = min
                return highestLevel
            },
            gameListCul(){
                let {gameList: list, highestLevel} = this
                let rd = []
                for(let i in list){
                    let {resourceDetail = {}} = list[i] || {}
                    let {taste_min_level} = resourceDetail
                    let disabled = false
                    if(taste_min_level !== undefined){
                        disabled = highestLevel >= taste_min_level ? false : true
                    }
                    let rule = resourceDetail.rule || this.PARAMS.game_default_rule
                    rd.push({
                        ...list[i],
                        ...resourceDetail,
                        disabled,
                        rule
                    })
                }
                return rd
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-social-game-taste-menu{
        .taste-menu-body{
            position: relative;
            width: @designWidth;
            height: @designHeight;
            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/p1.png'));
            .ele-item{
                position: absolute;
            }
            .title{
                color: #A36532;
                top: 22px;
            }
            .rule{
                width: 107px;
                height: 39px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/p2.png'));
                padding-top: 6px;
                color: #BD523D;
                top: 33px;
                right: 46px;
            }
            .game{
                top: 130px;
                left: 23px;
                width: 622px;
                height: 213px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/game_shadow.png'));
                .game-body{
                    margin-top: -39px;
                    margin-left: 7px;
                }
                .game-item+ .game-item{
                    margin-left: 7px;
                }
                .game-item.capture{
                    width: 116px;
                    height: 242px;
                }
                .game-item.play{
                    width: 116px;
                    height: 253px;
                    margin-top: -12px
                }
                .game-item.capture-9{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/capture_9.png'));
                    
                }
                .game-item.capture-13{
                    margin-left: 5px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/capture_13.png'));
                }
                .game-item.play-9{
                    > .user-photo-box{
                        top: 97px;
                    }
                    > .disabled-label{
                        top: 85px
                    }
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_9.png'));
                }
                .game-item.play-13{
                    > .user-photo-box{
                        top: 97px;
                    }
                    > .disabled-label{
                        top: 85px
                    }
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_13.png'));
                }
                .game-item.play-19{
                    > .user-photo-box{
                        top: 94px;
                    }
                    > .disabled-label{
                        top: 82px
                    }
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_19.png'));
                }

                .game-item.capture-9.disabled{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/capture_9_disabled.png'));
                }
                .game-item.capture-13.disabled{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/capture_13_disabled.png'));
                }
                .game-item.play-9.disabled{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_9_disabled.png'));
                }
                .game-item.play-13.disabled{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_13_disabled.png'));
                }
                .game-item.play-19.disabled{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/play_19_disabled.png'));
                }
                .game-item{
                    
                    position: relative;
                    .item-bcg{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        img{
                            width: 100%;
                            float: left;
                        }
                    }
                    > .user-photo-box{
                        position: absolute;   
                        top: 86px;
                        width: 73px;
                        height: 73px;
                        background: #79A6FF;
                        .photo-body-bx{
                            background: none;
                        }
                    }
                    .disabled-label{
                        position: absolute;
                        width: 61px;
                        height: 100px;
                        top: 73px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/taste/p3.png'));
                    }
                }
            }
        }
    }
</style>